import BigNumber from 'bignumber.js';
import { BigNumber as EthBigNumber } from 'ethers';
import { ReserveDataHumanized } from '@yldr/contract-helpers';
import { FeeAmount } from '@uniswap/v3-sdk';
import { EMarketName } from '../ui-config/marketsConfig';
import { EStrategyProvider } from '../hooks/yldr/useAlmPoolQuery';

export enum EPoolType {
  all = 'all',
  stable = 'stable',
  volatile = 'volatile',
}

export enum EPositionType {
  Pool = 'Pool',
  Position = 'Position',
  LeveragedPosition = 'LeveragedPosition',
}

export enum EControlPositionType {
  Unknown = 'Unknown',
  ManualPosition = 'ManualPosition',
  AlmPosition = 'AlmPosition',
}

export interface IPosition {
  controlType: EControlPositionType;
  almMarket?: {
    marketId: number;
    marketName: EStrategyProvider;
  };
  marketId?: number;
  marketName?: EMarketName;
  tokenId: number;
  token0: string;
  token1: string;
  amount0: string;
  amount1: string;
  liquidity: EthBigNumber;
  tickLower: number;
  tickCurrent: number;
  tickUpper: number;
  sqrtPriceX96: EthBigNumber;
  fee: number;
  fee0: string;
  fee1: string;
  vault?: string;
}

export interface ILeveragedPosition {
  uniswapV3Position: IPosition;
  debtAsset: string;
  debt: string;
  positionAddress: string;
  revenueFee0: string;
  revenueFee1: string;
  revenueFeePercent: string;
}

export interface IPositionToken {
  underlyingAsset: string;
  decimals: number;
  symbol: string;
  iconSymbol: string;
  name?: string;
  liquidity: number;
  fee: number;
  marketReferenceCurrencyFee: number;
  marketReferenceCurrencyValue: number;
  priceInMarketReferenceCurrency: number;
  availableLeverage: number;
  liquidationThreshold: number;
  variableRateSlope1: string;
  variableRateSlope2: string;
  optimalUsageRatio: string;
  borrowUsageRatio: string;
  totalVariableDebtUSD: string;
  baseVariableBorrowRate: string;
  totalLiquidityUSD: string;
  netWorth: number;
  netWorthUsd: number;
}

export interface IEnrichedUniswapPosition extends Omit<IPosition, 'token0' | 'token1' | 'fee0' | 'fee1'>{
  type: EPositionType;
  token0: IPositionToken;
  token1: IPositionToken;
  lowerPosition: BigNumber;
  upperPosition: BigNumber;
  positionInRange: boolean;
  availableLeverage: number;
  maxLeverage: number;
  marketReferenceCurrencyValue: number;
  ltv: number;
  liquidationThreshold: number;
  liquidationBonus: number;
  debtToken?: ReserveDataHumanized;
  debtAsset?: string;
  debt?: number;
  revenueFee0?: string;
  revenueFee1?: string;
  revenueFeePercent?: string;
  debtWithoutDecimals?: string;
  marketReferenceCurrencyDebt?: number;
  positionAddress?: string;
  borrowApy?: number;
  healthFactor?: number;
  uniswapLink?: string;
  poolType?: EPoolType;
  maxApr?: number;
  poolPrice: BigNumber;
  invertedPoolPrice: BigNumber;
  feeAmount: FeeAmount;
  collateral?: BigNumber;
  lowHealthFactor?: boolean;
  almMarketName?: EStrategyProvider;
  vault?: string;
}
