import React from 'react';
import {
  ListItemIcon,
  ListItemText,
  Box,
  MenuItem,
  Typography,
  MenuList as MuiMenuList,
  menuItemClasses,
  listItemIconClasses,
  typographyClasses,
} from '@mui/material';
import { IMenuListItem } from './types';

export interface IMenuListProps {
  items: IMenuListItem[];
  onSelect?: (index: number) => void;
}

export const MenuList = ({ items, onSelect }: IMenuListProps) => (
  <MuiMenuList>
    {items.map((item, index) => (
      <MenuItem
        key={item.label}
        sx={{
          borderBottom: index < (items.length - 1)
            ? '1px solid rgba(255, 255, 255, 0.08)'
            : 'unset',
          px: 4,
          py: 3,
          whiteSpace: 'unset',
          [`&.${menuItemClasses.disabled}`]: {
            opacity: 1,
            [`>.${listItemIconClasses.root}`]: { opacity: 0.5 },
            [`p.${typographyClasses.root}`]: { opacity: 0.5 },
          }
        }}
        disabled={item.disabled}
        onClick={() => onSelect?.(index)}
      >
        <ListItemIcon sx={{ mr: 4 }}>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          sx={{ ['>span']: { display: 'flex', flexDirection: 'column', gap: 1 } }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Typography variant="main14">
              {item.label}
            </Typography>
            {item.endIcon && (
              <ListItemIcon>
                {item.endIcon}
              </ListItemIcon>
            )}
          </Box>
          <Typography variant="secondary13" color="text.secondary">
            {item.description}
          </Typography>
        </ListItemText>
      </MenuItem>
    ))}
  </MuiMenuList>
)
